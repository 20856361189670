import Nav from 'components/Nav';
import Header from 'components/Header';
import Head from 'components/Head';
import Where from 'components/Where';
import We from 'components/We';
import Services from 'components/Services';
import ConctactUs from 'components/ContactUs';
import TopButton from 'components/TopButton';
import Footer from 'components/Footer';

function App() {
  return (
    <>
      <Header />
      <Nav />
      <main>
        <Head />
        <We />
        <Services />
        <Where />
        <ConctactUs />
      </main>
      <Footer />
      <TopButton />
    </>
  );
}

export default App;
