import { EnvelopeOpenIcon } from '@heroicons/react/20/solid';

export default function Header() {
  return (
    <>
      <header>
        <div className='container flex items-center justify-end px-5 md:px-0'>
          <a href='mailto:contacto@nuestratierraeventos.com' className='flex items-center justify-center gap-1 text-sm text-white'>
            <EnvelopeOpenIcon className="h-4 w-4 text-white" /> contacto@nuestratierraeventos.com
          </a>
        </div>
      </header>
    </>
  )
}

