import { useState } from 'react';
import { motion } from 'framer-motion';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import logo from 'assets/images/logo.png';

export default function Nav() {
  const [showMenu, setShowMenu] = useState(false);
  const navLinks = [
    { link: '#quienes-somos', title: 'Quienes somos' },
    { link: '#que-ofrecemos', title: 'Que ofrecemos' },
    { link: '#ubicacion', title: 'Ubicación' },
    { link: '#contactanos', title: 'Contactanos', callToAction: true }
  ];

  const toggleShowMenu = () => {
    setShowMenu(prev => !prev);
  }

  const variants = {
    open: { opacity: 1, height: '100%' },
    closed: { opacity: 0 },
  }

  return (
    <>
      <nav>
        <div className='container flex justify-end px-5 md:px-0'>
          <div className='grow'>
            <img src={logo} alt='' width={100} height={100} className='h-14 md:h-40 w-14 md:w-40' />
          </div>
          <button type='button' className='z-50 p-1 md:hidden' onClick={toggleShowMenu}>
            {showMenu ?
              <XMarkIcon className='h-10 w-10 text-white' /> :
              <Bars3Icon className='h-10 w-10 text-black' />
            }
          </button>
          <motion.div
            className='bg-theme w-full absolute top-0 left-0 md:hidden'
            animate={showMenu ? 'open' : 'closed'}
            variants={variants}>
            <div className='flex items-center justify-center w-full h-full'>
              <ul className='list-none text-center'>
                {
                  navLinks.map((item, index) => (
                    <motion.li key={index} className='py-4 text-3xl text-white'>
                      <a href={item.link} onClick={toggleShowMenu}>{item.title}</a>
                    </motion.li>)
                  )
                }
              </ul>
            </div>
          </motion.div>
          <ul className='list-none hidden md:flex flex-row items-center text-black text-lg gap-5'>
            {
              navLinks.map((item, index) => {
                if (item.callToAction) {
                  return (
                    <li key={index}>
                      <a
                        href={item.link}
                        className='flex items-center justify-center gap-2 bg-theme bg-opacity-70 rounded-full px-4 py-2 text-white custom-shadow'>
                        {item.title} <ArrowRightIcon className='h-5 w-5 text-white' />
                      </a>
                    </li>
                  )
                } else {
                  return (
                    <li key={index}>
                      <a href={item.link} className='nav-link'>{item.title}</a>
                    </li>
                  )
                }
              })
            }
          </ul>
        </div>
      </nav>
    </>
  )
}