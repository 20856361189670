export default function We() {
  return (
    <>
      <div className='bg-white w-full flex justify-center items-center' id='quienes-somos'>
        <div className='container text-center py-16 px-5'>
          <h3 className='mb-4'>Quienes somos</h3>
          <p className='text-lg'>Fusionamos tradición y diseño. El equilibrio entre naturaleza y arquitectura en 42 hectáreas.</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-20'>
            <video playsInline autoPlay muted loop className='rounded-xl w-full max-w-[800px] mb-8 md:mb-0'>
              <source src='/assets/videos/background.mp4' type='video/mp4' />
            </video>
            <div className='flex flex-col gap-6 text-left md:px-10 justify-center text-neutral-600'>
              <p><b>Nuestra Tierra</b> es un innovador proyecto de polo, cuya filosofía fusiona las tradiciones del deporte y un estilo de vida contemporáneo. Está dedicado a una familia amante del polo, que disfruta la naturaleza, la arquitectura y el diseño.</p>
              <p>Su visión ha sido crear un lugar moderno inspirado en la estética del campo tradicional argentino.</p>
              <p>En especial se buscó generar un equilibrio entre las grandes arboledas y lo plano de las canchas con con la  arquitectura de sus construcciones.</p>
              <p>El campo está ubicado en la cercanía de Open Door a 70 km de la Capital. Ocupa un predio de 42 hectáreas.</p>
              <p><b>Nuestra Tierra:</b> el “friendly polo” concepto donde se mezcla por  primera vez naturaleza y diseño.</p>
              <div className='border-b-4 w-20 border-theme'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}