import instagram from 'assets/images/icon-instagram.svg';

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <footer>
        <div className='container grow text-center md:text-left'>
          <p className='font-merriweather text-xl'>Nuestra Tierra Eventos <span className='text-xs'>®</span></p>
          <p className='text-sm opacity-50'>Todos los derechos reservados | {year}</p>
        </div>
        <div>
          <a href='https://www.instagram.com/nuestratierraeventos/' target='_blank'>
            <img src={instagram} alt='Instagram' width={42} height={42} />
          </a>
        </div>
      </footer>
    </>
  )
}