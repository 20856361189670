
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Autoplay, Pagination, Navigation } from 'swiper/modules';

import slideOne from 'assets/images/slide_1.jpeg';
import slideTwo from 'assets/images/slide_2.jpeg';
import slideThree from 'assets/images/slide_3.jpeg';
import slideFour from 'assets/images/slide_4.jpeg';
import slideFive from 'assets/images/slide_5.jpeg';

import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Services() {
  const slides = [
    { image: slideOne },
    { image: slideTwo },
    { image: slideThree },
    { image: slideFour },
    { image: slideFive },
  ];

  return (
    <>
      <div id='que-ofrecemos' className='min-w-0'>
        <div className='container text-center px-5 md:px-0'>
          <h3 className='mb-4'>Que ofrecemos</h3>
          <p className='text-lg'>Espacio único para eventos con naturaleza, servicios y vistas inolvidables</p>
          <div className='pt-10 md:py-10 flex flex-col items-center justify-center gap-10'>
            <div className='w-full md:w-3/4 flex flex-col justify-center items-center gap-10 '>
              <p><b>Nuestra Tierra</b> es un lugar único orientado a prestar servicios sociales , corporativos y espectáculos.<br />
                Disfrutar de la naturaleza es el principl objetivo.<br />
                Frondosas arboledas y vistas a la cancha de polo lograrán que su evento sea especial, emotivo y quede en sus recuerdos.
              </p>
              <div className='border-b-4 w-20 border-theme'></div>
            </div>
            <div className='w-full flex items-center justify-center'>
              <div className='w-full lg:w-2/4'>
                <Swiper
                  speed={2000}
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay={{ delay: 4500, disableOnInteraction: false }}
                  pagination={{ clickable: true }}
                  loop={true}
                  modules={[Autoplay, Pagination, Navigation, Controller]}>
                  {slides.map((slide, index) => 
                    <SwiperSlide key={index}>
                      <img src={slide.image} alt='' className='rounded-xl w-full h-full' />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}