/* eslint import/no-webpack-loader-syntax: off */

import { useEffect, useState, useRef } from 'react';
import mapboxgl from '!mapbox-gl';

export default function Where() {
  mapboxgl.accessToken = 'pk.eyJ1Ijoicm9kcmlnb3NvYnJlcm8iLCJhIjoiY2xkZXh5aXkzMGNpczNvbnd6Yjlwb241aSJ9.mf3IdWOeLJLzIs9KbO8Ufg';

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-59.0618174);
  const [lat, setLat] = useState(-34.4436466);
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });

    new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);
  });

  return (
    <>
      <div className='bg-white w-full flex flex-col md:flex-row justify-center items-center' id='ubicacion'>
        <div className='container text-center py-10 px-5'>
          <h3 className='mb-4'>Ubicación</h3>
          <p className='text-neutral-600 text-lg'>Nuestra Tierra Eventos se encuentra en el km 169 de la ruta 6 en la ciudad de Open Door.</p>
        </div>
        <div ref={mapContainer} className='map-container' />
      </div>
    </>
  )
}