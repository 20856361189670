import { ArrowRightIcon } from '@heroicons/react/24/solid';

export default function Head() {
  return (
    <>
      <div className='container grid grid-cols-1 md:grid-cols-2'>
        <div className='flex flex-col items-center justify-center px-5 md:px-20'>
          <h1 className='text-center header-title'>
            Un lugar <span className='font-satisfy text-[80px] md:text-[90px] text-theme'>único</span> para tu evento
          </h1>
          <h2 className='text-center mb-10'>Contactanos y descubrí un lugar único <span className='text-theme'>donde realizar tu evento</span>.</h2>
          <a href='#contactanos' className='bg-theme text-white rounded-full px-8 py-4 text-xl flex items-center gap-3 custom-shadow'>
            Contactanos ahora
            <ArrowRightIcon className='h-6 w-6 text-white' />
          </a>
        </div>
        <div className='header'>
          <video playsInline autoPlay muted loop className='mask'>
            <source src='/assets/videos/background.mp4' type='video/mp4' />
          </video>
        </div>
      </div>
    </>
  )
}