import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

import spinner from 'assets/images/icon-loading-claim.png';

import 'react-toastify/dist/ReactToastify.css';

export default function ConctactUs() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const body = {
        contacto: {
          email: data.email,
          nombre: data.name,
          apellido: data.lastname,
          consulta: data.message,
          telefono: data.phone
        }
      };
      const response = await fetch('https://api.sheety.co/32070b2ff82f76fc3900a8b0f26e3f7c/landing/contactos', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        toast.success('Tu consulta ha sido enviada', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error('Error al enviar tu consulta', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      toast.error('Error al enviar tu consulta', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className='container text-center px-5 md:px-0' id='contactanos'>
        <h3 className='mb-4'>Contactanos</h3>
        <p className='text-lg'>Completá el formulario y estaremos respondiendote lo más rápido posible</p>
        <div className='flex flex-col items-center justify-center mt-10'>
          <div className='w-20 border-t-4 border-theme mb-10'></div>
          <div className='bg-white w-full md:w-2/4 rounded-t-xl shadow-custom p-10 md:p-20'>
            <form className='flex flex-col gap-5' onSubmit={handleSubmit(onSubmit)}>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <div className='relative'>
                  <input type='text' placeholder='Nombre' {...register('name', { required: true })} />
                  <div className='rounded-full bg-theme bg-opacity-50 w-3 h-3 absolute right-6 top-6'></div>
                </div>
                <div className='relative'>
                  <input type='text' placeholder='Apellido' {...register('lastname', { required: true })} />
                  <div className='rounded-full bg-theme bg-opacity-50 w-3 h-3 absolute right-6 top-6'></div>
                </div>
              </div>
              <div className='relative'>
                <input type='email' placeholder='Email' {...register('email', { required: true })} />
                <div className='rounded-full bg-theme bg-opacity-50 w-3 h-3 absolute right-6 top-6'></div>
              </div>
              <input type='tel' placeholder='Teléfono' {...register('phone', { required: false })} />
              <textarea placeholder='Consulta' rows={4} className='rounded-2xl' {...register('message', { required: false })} />
              <div className='text-xs text-neutral-400 flex items-center gap-2'>
                <div className='rounded-full bg-theme bg-opacity-50 w-3 h-3'></div>
                Obligatorio
              </div>
              <button 
                type='submit' 
                className='bg-theme text-white rounded-full flex items-center justify-center w-full h-[60px] py-4 text-lg font-merriweather shadow-custom disabled:opacity-60'
                disabled={loading}>
                {loading ? <img src={spinner} width={20} height={20} className='animate-spin' /> : <span>Enviar consulta</span>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}