import { ArrowUpIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

export default function TopButton() {
  const [showButton, setShowButton] = useState(false)

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
    }

    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    }
  }, [])

  return (
    <>
      <div className='fixed bottom-10 right-10 z-50'>
        <button
          type='button'
          className={`rounded-full p-3 bg-theme custom-shadow animate-bounce transition-all duration-500 ${showButton ? 'opacity-100' : 'opacity-0'} `}
          onClick={handleScrollToTop}>
          <ArrowUpIcon className='h-8 w-8 text-white' />
        </button>
      </div>
    </>
  )
}